@import "core/index.scss";
@mixin btnColor(
	$bgColor,
	$textColor: $color-white,
	$hollowColor: false,
	$hollowHoverBg: false,
	$hoverColor: false,
	$borderColor: false,
	$hoverBg: false,
) {
	@if $hollowColor == false {
		$hollowColor: $bgColor;
	}

	@if $borderColor == false {
		$borderColor: $bgColor
	}

	background-color: $bgColor;
	// border-color: $borderColor;
	color: $textColor;

	&:not(.no-hover):not(:disabled):not(.nude) {
		@include mediaMin {
			&:hover {
				@if $hoverBg == false {
					background-color: darken($bgColor, $darken-ratio);
					// border-color: darken($bgColor, $darken-ratio);
				}
				@else {
					background-color: $hoverBg;
					// border-color: $hoverBg;
				}

				@if $hoverColor == false {
					color: $textColor;
				}
				@else {
					color: $hoverColor;
				}
			}
		}

		&:active {
			@if $hoverBg == false {
				background-color: darken($bgColor, $darken-ratio);
				// border-color: darken($bgColor, $darken-ratio);
			}
			@else {
				background-color: $hoverBg;
				// border-color: $hoverBg;
			}
			
			@if $hoverColor == false {
				color: $textColor;
			}
			@else {
				color: $hoverColor;
			}
		}
	}

	&.outline {
		background-color: transparent;
		color: $hollowColor;
		border-color: $borderColor;

		&:not(.no-hover):not(:disabled):not(.nude) {
			@include mediaMin {
				&:hover {
					border-color: $bgColor;
					color: $textColor;
					@if($hollowHoverBg == false) {
						background-color: $bgColor;
					}
					@else {
						background-color: $hollowHoverBg;
					}
				}
			}

			&:active {
				@if $hoverBg == false {
					background-color: lighten($bgColor, $darken-ratio);
					border-color: lighten($bgColor, $darken-ratio);
				}
				@else {
					background-color: $hoverBg;
					border-color: $hoverBg;
				}

				@if $hoverColor == false {
					color: $textColor;
				}
				@else {
					color: $hoverColor;
				}
			}
		}
	}

	&.nude {
		background-color: transparent;
		border: none;
		padding: 0;
	}
}

@mixin btnColors() {
	@include btnColor($color-gray, $color-text, $color-text);

	&.primary {
		@include btnColor($color-primary, $color-white);
	}

	&.primary-dark {
		@include btnColor($color-primary-dark, $color-white);
	}

	&.text {
		@include btnColor($color-text, $color-white);
	}

	&.gray-darker {
		@include btnColor($color-gray-darker, $color-white);
	}

	&.white {
		@include btnColor($color-white, $color-text);
	}

	&.red {
		@include btnColor($color-red-bg, $color-red);
	}

	&.blue {
		@include btnColor($color-blue, $color-white);
	}

	&.blue-bg {
		@include btnColor($color-blue-bg, $color-blue);
	}

	&.blue-light {
		@include btnColor($color-blue-light, $color-text);
	}

	&.blue-medium {
		@include btnColor($color-blue-medium, $color-white);
	}

	&.blue-light-bg {
		@include btnColor($color-blue-light-bg, $color-blue-light);
	}

	&.orange {
		@include btnColor($color-orange-bg, $color-orange);
	}

	&.green {
		@include btnColor($color-green-bg, $color-green);
	}

	&.purple {
		@include btnColor($color-purple-bg, $color-purple);
	}

	&.pink {
		@include btnColor($color-pink, $color-white);
	}

		&.pink-40 {
			@include btnColor(rgba($color-pink, .4), $color-white);
		}

		&.pink-20 {
			@include btnColor(rgba($color-pink, .2), $color-white);
		}
	
	&.pink-bg {
		@include btnColor($color-pink-bg, $color-pink);
	}

	&.pink-light {
		@include btnColor($color-pink-light, $color-white);
	}

		&.pink-light-40 {
			@include btnColor(rgba($color-pink-light, .4), $color-white);
		}

		&.pink-light-20 {
			@include btnColor(rgba($color-pink-light, .2), $color-pink);
		}

	&.pink-dark {
		@include btnColor($color-pink-dark, $color-white);
	}

		&.pink-dark-40 {
			@include btnColor(rgba($color-pink-dark, .4), $color-white);
		}

		&.pink-dark-20 {
			@include btnColor(rgba($color-pink-dark, .2), $color-white);
		}

	&.black {
		@include btnColor($color-black, $color-white, false, lighten($color-black, $lighten-ratio));
	}

	&.bg {
		@include btnColor($color-bg, $color-text, false, lighten($color-bg, $lighten-ratio));
	}

	&.error {
		@include btnColor($color-error, $color-white);
	}

	&.success {
		@include btnColor($color-success, $color-white);
	}

	&.apple {
		@include btnColor(lighten($color-black, 70%), $color-black, $color-black, false, $color-black);
	}

	&.google {
		@include btnColor(lighten($color-google-red, 30%), $color-google-red, $color-google-red, false, $color-google-red);
	}
}

.btn {
	@include font-semibold;
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	//cursor: pointer;
	
	font-size: 1.5rem;
	height: 5rem;
	line-height: 1;
	padding: 0 2rem 0;
	border-radius: $radius-general;
	
	@include mediaMin {
		transition: background-color $transition-hover ease, color $transition-hover ease, border-color $transition-hover ease;
	}
	
	@include media {
		transition: background-color $transition-superfast ease, color $transition-superfast ease, border-color $transition-superfast ease;
		height: 4rem;
		padding: 0 1.5rem 0;
	}
	
	&.outline {
		border: .1rem solid $color-gray;
	}

	&.underline {
		text-decoration: underline;
	}

	// Sizes & Shapes

	&.rounded {
		border-radius: 2rem;
	}

	&.block {
		width: 100%;
		display: flex;
		padding-left: 1rem;
		padding-right: 1rem;

		.btn-content {
			justify-content: center;
		}
	}

	&.big {
		@include font-bold;
		height: 6rem;
		font-size: 1.8rem;
		padding: 0 2.1rem;
		letter-spacing: 0.02em;

		@include media {
			height: 5rem;
		}
	}

	&.bold {
		@include font-bold;
	}

	&.small {
		height: 3.6rem;
		font-size: 1.3rem;
		padding: 0 1.6rem;

		.btn-icon {
			font-size: 1.6rem;
		}

		.btn-iconwrap {
			height: 1.2rem;
		}
	}

	&.smaller {
		height: 2.4rem;
		padding: 0 1rem;
		font-size: 1.2rem;

		.btn-icon {
			font-size: 1rem;
		}

		.btn-iconwrap {
			height: 1rem;
		}
	}

	&.smallest {
		height: 2rem;
		padding: 0 .8rem;
		font-size: 1rem;

		.btn-icon {
			font-size: .6rem;

			&.pre {
				margin-right: .5rem;
			}
		}

		.btn-iconwrap {
			height: .6rem;
		}
	}

	&.wide {
		padding: 0 3rem;
	}

	&.narrow {
		padding: 0 .5rem;
	}

	&.icon-only {
		padding: 0;
		min-width: 5rem;

		@include media {
			min-width: 4rem;
		}

		.btn-icon {
			margin-left: 0!important;
			margin-right: 0!important;
		}

		&.rounded {
			border-radius: 50%;
		}

		&.small {
			min-width: 3.6rem;
		}

		&.smaller {
			min-width: 2.4rem;
		}

		&.big {
			min-width: 6rem;

			@include media {
				min-width: 5rem;
			}
		}
	}

	@include btnColors;

	// Statusses

	&.show-status {
		.btn-content {
			opacity: 0;
			transform: scale(.9);
		}

		.btn-statuswrap {
			opacity: 1;
			transform: scale(1);
		}
	}

	// Inner Components

	.btn-content {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		transition: opacity $transition-hover ease, transform $transition-hover ease;
	}

	.btn-statuswrap {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		transition: opacity $transition-hover ease, transform $transition-hover ease;
		transform: scale(1);
		transform: translate3d(0, 0, 0);

		.icon-spinner {
			@include spin();
		}

		.btn-status {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate3d(-50%, -50%, 0);
		}
	}

	// Icons
	
	.btn-icon {
		font-size: 1.8rem;
		margin-right: 1rem;

		&.pre {
			margin-right: 1rem;
		}
		
		&.post {
			font-size: 1rem;
			margin-left: 1rem;
		}
	}

	.btn-iconwrap {
		height: 1.8rem;
		display: inline-block;

		.image {
			height: 100%;
		}
	}

	&.big-icon {
		.btn-icon {
			font-size: 2.2rem;
		}

		&.small {
			.btn-icon {
				font-size: 1.8rem;
			}
		}
	}

	&.small-icon {
		.btn-icon {
			font-size: 1.6rem;
		}

		&.small {
			.btn-icon {
				font-size: 1.3rem;
			}
		}
	}

	// States

	&:disabled{
		cursor: auto;
		&:not(.status-loading) {
			opacity: 0.5
		}
	}
}